import React from 'react';
import { Link } from 'react-router-dom';
import { updates, testimonials } from '../data';


function Home() {
  return (
    <div className="content">
      <div className="left-column">
        <h2>Latest Updates</h2>
        {updates.map(update => (
          <div key={update.id}>
            <h3>{update.title}</h3>
            <p className="date">{update.date}</p>
            <p>
              {update.summary}
              <Link to={`/updates#${update.id}`}>Read more</Link>
            </p>
          </div>
        ))}
      </div>
      <div className="main-column">
        <img src='/mainImage.JPG' alt="Main Image" />
        <p className="caption">Welcome to Noah Driker Consulting</p>
        <p className="main-article">
          I specialize in providing top-notch software engineering solutions to help your business thrive. Explore my services and discover how I can assist you in achieving your goals.
        </p>
      </div>
      <div className="right-column">
        <h2>Why Choose Noah</h2>
        {testimonials.map(testimonial => (
          <div key={testimonial.id}>
            <h3>{testimonial.title}</h3>
            <p className="date">{testimonial.date}</p>
            
            <p>
              {testimonial.summary}
              <Link to={`/testimonials#${testimonial.id}`}>Read more</Link>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
