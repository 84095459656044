const projects = [
    {
      id: 1,
      title: "Crux Compiler",
      caption: "C-syntax-like Compiler",
      image: "/projects/1.jpeg",
      description: "This compiler was built for the second course in a three course sequence on language processors and compilers. I built a compiler for the Crux language using Java and Antlr, targeting x86 assembly.",
      githubLink: "https://github.com/ndriker/cruxCompiler"
    },
    {
      id: 2,
      title: "TINY Compiler",
      caption: "SSA Optimizing Compiler",
      image: "/projects/2.jpeg",
      description: "This project involved building an SSA Optimizing Compiler. This compiler implemented multiple optimizations, and it contains a custom register allocator to reduce MOV instructions in the code output.",
      githubLink: "https://github.com/ndriker/tinyCompiler"
    },
    {
        id: 3,
        title: "UHCI Hub Device Model",
        caption: "Adding USB Device Support",
        image: "/projects/3.jpeg",
        description: "I worked on adding USB device support to the Via project by porting the QEMU UHCI Hub Device model to Linux Kernel Library. Via is a tool for testing device drivers for devices connected to warehouse scale computers.",
        githubLink: "https://github.com/ndriker/via"
    },    
    {
        id: 4,
        title: "Distributed Systems",
        caption: "OpenMPI",
        image: "/projects/4.jpeg",
        description: "Two implementation files from a class on Parallel and Distributed Systems. In this I implement a Map/Reduce with interprocess communication with OpenMPI.",
        githubLink: "https://github.com/ndriker/distributedSystems"
    },        
    {
        id: 5,
        title: "Computer Vision",
        caption: "High-fidelity 3D Model Reconstruction",
        image: "/projects/5.jpeg",
        description: "This was for a course on Computer Vision, where I was tasked with creating a high-fidelity 3D Model (with color) from a provided set of images.",
        githubLink: "https://github.com/ndriker/computerVision"
    },   
    {
        id: 6,
        title: "MuJoCo Optimization",
        caption: "Compare Reinforcement Learning with Genetic Algorithm",
        image: "/projects/6.jpeg",
        description: "This project was for a class in optimization. I implemented a custom genetic algorithm to train a robot to walk, and compared the results against a PPO Reinforcement Learning model (see report for more details).",
        githubLink: "https://github.com/ndriker/MuJoCo"
    },             
    // Add more projects as needed
  ];
  
  export default projects;