import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import projects from '../projects';
import './ProjectDetail.css';

function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const project = projects.find(p => p.id === parseInt(id));

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="project-detail">
      <button className="back-button" onClick={() => navigate('/projects')}>Back to Projects</button>
      <div className="project-content">
        <img src={project.image} alt={project.title} className="project-detail-thumbnail" />
        <h2 className="project-detail-title">{project.title}</h2>
        <p className="project-detail-caption">{project.caption}</p>
        <p className="project-detail-description">{project.description}</p>
        <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="project-detail-github-link">
          View on GitHub
        </a>
      </div>
    </div>
  );
}

export default ProjectDetail;
