import React from 'react';
import { updates } from '../data';

function Updates() {
  return (
    <div>
      <h1>Updates</h1>
      {updates.map(update => (
        <section id={update.id} key={update.id}>
          <h2>{update.title}</h2>
          <p className="date">{update.date}</p>

          <p>{update.fullText}</p>
        </section>
      ))}
    </div>
  );
}

export default Updates;
