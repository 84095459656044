import React from 'react';
import './Consulting.css';

function Consulting() {
  return (
    <div className="consulting">
      <h2 className="consulting-title">Consulting Services</h2>
      <div className="consulting-menu">
        <div className="menu-item">
          <h3 className="menu-item-title">Cloud Engineering and Migratiton Services</h3>
          <p className="menu-item-description">
            Seamlessly transition your infrastructure to the cloud and optimize it for scalability and efficiency. My cloud engineering services ensure a smooth migration with minimal downtime and maximum performance.
          </p>
        </div>
        <div className="menu-item">
          <h3 className="menu-item-title">Full-stack Web and Mobile Development</h3>
          <p className="menu-item-description">
            Develop robust and scalable web/mobile applications from front-end to back-end. I cover everything from user interfaces to server management, providing end-to-end solutions tailored to your specific requirements.
          </p>
        </div>
        <div className="menu-item">
          <h3 className="menu-item-title">IoT and Embedded Software Solutions</h3>
          <p className="menu-item-description">
            Innovate with Internet of Things (IoT) and embedded software solutions. I design and develop smart systems that integrate seamlessly with your existing infrastructure, enhancing connectivity and functionality.
          </p>
        </div>
        <div className="menu-item">
          <h3 className="menu-item-title">Custom Software Development</h3>
          <p className="menu-item-description">
            Create tailored software solutions to meet your unique business needs. My custom software development services range from specialized tools to comprehensive applications, ensuring a perfect fit for your requirements.
          </p>
        </div>
        <div className="menu-item">
          <h3 className="menu-item-title">ChatGPT and Custom GPT Integration</h3>
          <p className="menu-item-description">
            Enhance your website with advanced conversational AI capabilities. I provide intelligent and interactive user experiences through ChatGPT and custom GPT integrations, customized to your specific needs.
          </p>
        </div>                
      </div>
      <div className="menu-item">
          <h3 className="menu-item-title">Site Maintenance and Management</h3>
          <p className="menu-item-description">
            Keep your web applications running smoothly with my site maintenance and management services. I offer comprehensive support, including updates, security monitoring, and performance optimization.
          </p>
        </div>                

    </div>
  );
}

export default Consulting;
