import React from 'react';
import { testimonials } from '../data';

function Testimonials() {
  return (
    <div>
      <h1>Why Choose Noah</h1>
      {testimonials.map(testimonial => (
        <section id={testimonial.id} key={testimonial.id}>
          <h2>{testimonial.title}</h2>
          <p className="date">{testimonial.date}</p>
          <p>{testimonial.fullText}</p>
        </section>
      ))}
    </div>
  );
}

export default Testimonials;
