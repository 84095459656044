import React from 'react';
import { Link } from 'react-router-dom';
import projects from '../projects';
import './Projects.css';

function Projects() {
  return (
    <div className="projects-grid">
      {projects.map(project => (
        <Link key={project.id} to={`/projects/${project.id}`} className="project-tile">
          <img src={project.image} alt={project.title} className="project-image" />
          <h3 className="project-title">{project.title}</h3>
          <p className="project-caption">{project.caption}</p>
        </Link>
      ))}
    </div>
  );
}

export default Projects;
