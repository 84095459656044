export const updates = [
    {
      id: 'cloud-migration-consulting',
      title: 'New Service Launched: Cloud Migration Consulting',
      date: 'June 08, 2024',
      summary: 'I am excited to announce the launch of my new Cloud Migration Consulting Service...',
      fullText: 'I am excited to announce the launch of my new Cloud Migration Consulting Service. I will help you seamlessly migrate your infrastructure to the cloud, ensuring minimal downtime and maximum efficiency. Contact me today to learn more!'
    },
    {
      id: 'site-launch',
      title: 'Site Launch',
      date: 'June 08, 2024',
      summary: 'After providing software engineering consulting services for the past three years, I am excited to announce...',
      fullText: 'After providing software engineering consulting services for the past three years, I am excited to announce the launch of this new site! Check out the services I provide in the Consulting section, and you can verify my qualifications in the Resume section or by viewing some of my projects. Hope to help your business moving forward!'
    }
  ];
  
  export const testimonials = [

    {
      id: 'success-stories',
      title: 'Success Stories',
      date: 'January 30, 2023',
      summary: 'Noah and team built a website for a tax accounting business...',
      fullText: 'Noah and team built a website for a tax accounting business. From ideation, design, construction, to final testing and touching up details. This site was designed to be mobile friendly and the team handled all hosting details and configuring an auto-emailing service.'
    }
  ];
  

  export const quotes = [
    "The best way out is always through. — Robert Frost",
    "The only limit to our realization of tomorrow is our doubts of today. — Franklin D. Roosevelt",
    "Life is short, and it is up to you to make it sweet. — Sarah Louise Delany",
    "What we think, we become. — Buddha",
    "The unexamined life is not worth living. — Socrates",
    "To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment. — Ralph Waldo Emerson",
    "In the end, we only regret the chances we didn't take. — Lewis Carroll",
    "Believe you can and you're halfway there. — Theodore Roosevelt",
    "The journey of a thousand miles begins with one step. — Lao Tzu",
    "Life is what happens when you're busy making other plans. — John Lennon",
    "Happiness is not something ready-made. It comes from your own actions. — Dalai Lama",
    "To live is the rarest thing in the world. Most people exist, that is all. — Oscar Wilde",
    "Success is not final, failure is not fatal: It is the courage to continue that counts. — Winston Churchill",
    "Not all those who wander are lost. — J.R.R. Tolkien"
  ];
  