import React from 'react';
import './Resume.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { faFilePdf} from '@fortawesome/free-regular-svg-icons';


function Resume() {
  return (
    <div className="resume">
      <h2 className="resume-title">Resume</h2>
      <div className="resume-links">
        <a href="https://github.com/ndriker" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} className="resume-icon" />
        </a>
        <a href="https://www.linkedin.com/in/noah-driker/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="resume-icon" />
        </a>
      </div>
      <p>My most up-to-date resume, as of June 04, 2024.</p>
      <div className="resume-download">
        <a href='/NoahDrikerResume.pdf' download>
          <FontAwesomeIcon icon={faFilePdf} className="resume-download-icon" />
          Download Resume
        </a>
      </div>
    </div>
  );
}

export default Resume;
