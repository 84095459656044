import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import { quotes } from './data';
import Home from './components/Home';
import Consulting from './components/Consulting';
import Resume from './components/Resume';
import Projects from './components/Projects';
import ProjectDetail from './components/ProjectDetail'
import Updates from './components/Updates';
import Testimonials from './components/Testimonials';
// import TrainBoard from './components/TrainBoard';
function App() {
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const getCurrentPSTDate = () => {
      const now = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', timeZone: 'America/Los_Angeles' };
      const pstDate = now.toLocaleDateString('en-US', options);
      setCurrentDate(pstDate);
    };

    getCurrentPSTDate();
    const interval = setInterval(getCurrentPSTDate, 3600000); // Update date every hour

    return () => clearInterval(interval);
  }, []);
  
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  // const phrases = [
  //   "HELLO WORLD",
  //   "THIS IS A TEST",
  //   "ANOTHER PHRASE",
  //   "YET ANOTHER PHRASE"
  // ];

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="date-tickers">
            <div className="date">{currentDate}</div>
            <div className="tickers">
            <marquee scrollamount="3">
                <div className="marquee-content">
                  {quotes.map((quote, index) => (
                    <span key={index}>{quote}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;♦</span>
                  ))}
                </div>
              </marquee>
            {/* <TrainBoard phrases={quotes} interval={10000}/> */}
            </div>
          </div>
          <h1 className="headline"><Link to="/" className="home-link">Noah Driker Consulting</Link></h1>
          <nav className="navbar">
            <button className="mobile-menu-button" onClick={toggleMobileMenu}>
              &#9776;
            </button>
            <ul className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
              <li><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
              <li className={`dropdown ${isMobileMenuOpen ? 'hidden' : ''}`}>
                <Link to="/consulting" className="dropbtn">Consulting</Link>
                <div className="dropdown-content">
                  <Link to="/consulting">Services</Link>
                  <Link to="/updates">Updates</Link>
                  <Link to="/testimonials">Testimonials</Link>
                </div>
              </li>
              <li className={`mobile-sublink ${isMobileMenuOpen ? '' : 'hidden'}`}>
                <Link to="/consulting" onClick={closeMobileMenu}>Consulting</Link>
              </li>
              <li className={`mobile-sublink ${isMobileMenuOpen ? '' : 'hidden'}`}>
                <Link to="/updates" onClick={closeMobileMenu}>&nbsp;&nbsp;Updates</Link>
              </li>
              <li className={`mobile-sublink ${isMobileMenuOpen ? '' : 'hidden'}`}>
                <Link to="/testimonials" onClick={closeMobileMenu}>&nbsp;&nbsp;Testimonials</Link>
              </li>
              <li><Link to="/resume" onClick={closeMobileMenu}>Resume</Link></li>
              <li><Link to="/projects" onClick={closeMobileMenu}>Projects</Link></li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetail />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
